import { CirclesWithBar } from 'react-loader-spinner'

export default function FullPageSpinner() {
  return (
    <div className='min-h-screen flex items-center justify-center'>
      <CirclesWithBar
        height='100'
        width='100'
        color='#4fa94d'
        wrapperStyle={{}}
        wrapperClass=''
        visible
        outerCircleColor=''
        innerCircleColor=''
        barColor=''
        ariaLabel='circles-with-bar-loading'
      />
    </div>
  )
}
