import { useState } from 'react'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import Navbar from './navbar'

function Header({
  children,
  bannerBackground = 'video',
  imageURL,
  videoURL,
  staticImageURL,
  className,
}) {
  const [isFixed, setIsFixed] = useState(false)

  window.addEventListener('scroll', () => {
    if (window.scrollY > window.innerHeight) {
      setIsFixed(true)
    } else {
      setIsFixed(false)
    }
  })

  // useEffect(() => {
  //   const { body } = document
  //   let topPosition = 0
  //   function handleNavbar() {
  //     const currentScroll = window.pageYOffset

  //     if (currentScroll <= 0) {
  //       body.classList.remove('scroll-up')
  //     }
  //     if (currentScroll > topPosition && !body.classList.contains('scroll-down')) {
  //       body.classList.remove('scroll-up')
  //       body.classList.add('scroll-down')
  //     }
  //     if (currentScroll < topPosition && body.classList.contains('scroll-down')) {
  //       body.classList.remove('scroll-down')
  //       body.classList.add('scroll-up')
  //     }

  //     topPosition = currentScroll
  //   }

  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', handleNavbar)
  //   }
  //   return () => {
  //     if (typeof window !== 'undefined') {
  //       window.removeEventListener('scroll', handleNavbar)
  //     }
  //   }
  // }, [])

  return (
    <header>
      {bannerBackground === 'video' ? (
        <VideoBanner videoURL={videoURL} className={className}>
          {children}
        </VideoBanner>
      ) : (
        <ImageBanner imageURL={imageURL} staticImageURL={staticImageURL}>
          {children}
        </ImageBanner>
      )}

      <section
        className={`transition-all ease-in-out bottom-12 right-6 rounded-full text-primary z-10 ${
          isFixed ? `fixed block` : 'hidden'
        }`}
      >
        <button
          className='cursor-pointer'
          onClick={() => {
            window.scrollTo(0, 0)
          }}
        >
          <BsFillArrowUpCircleFill size={40} />
        </button>
      </section>
    </header>
  )
}
function VideoBanner({
  className = 'bg-primary',
  videoURL = 'https://test.afro-group.net/public/sigam/segam-home.mp4',
  children,
}) {
  // function VideoBanner({ children }) {
  return (
    // <section className='header-container text-white relative'>
    //   <div className='bg-video'>
    //     <video className='bg-video__content' autoPlay muted loop>
    //       <source src={videoURL} type='video/mp4' />
    //       your browser is not supported!
    //     </video>
    //   </div>
    //   <div className='linear-style'>
    //     <div className='pb-7'>
    //       <Navbar />
    //     </div>
    //     <div className='pt-5 pt-md-0 pb-7'>{children}</div>
    //   </div>
    // </section>
    <>
      <Navbar />
      <section className='hidden md:block'>
        <div className='grid grid-cols-3'>
          <div className={`col-span-1 text-white ${className}`}>
            <div className='my-auto h-full px-6'>{children}</div>
          </div>
          <div className='col-span-2'>
            <video className='bg-video__content' autoPlay muted loop>
              <source src={videoURL} type='video/mp4' />
              your browser is not supported!
            </video>
          </div>
        </div>
      </section>
      <section className='block md:hidden'>
        <div className='header-container text-white relative'>
          <div className='bg-video'>
            <video className='bg-video__content' autoPlay muted loop>
              <source src={videoURL} type='video/mp4' />
              your browser is not supported!
            </video>
          </div>
          <div className='linear-style'>
            <div className='pt-5 pt-md-0 pb-7'>{children}</div>
          </div>
        </div>
      </section>
    </>
  )
}

function ImageBanner({ imageURL, children, staticImageURL }) {
  const url = staticImageURL ? `${staticImageURL}` : `${imageURL}`
  return (
    <section
      className='header-container text-white'
      style={{
        backgroundImage: `url(${url})`,
      }}
    >
      <div className='linear-style'>
        <div className='pb-7'>
          <Navbar />
        </div>
        <div className='pt-5 pt-md-0 pb-7'>{children}</div>
      </div>
    </section>
  )
}

export default Header
