import greenLogo from 'assets/images/home/green-logo.webp'
import { navData } from 'data/navbar'
import { useState } from 'react'
import { HiOutlineMenu } from 'react-icons/hi'
import { Link, NavLink } from 'react-router-dom'
import Container from './contaienr'
import SideNavbar from './nav-sidebar'

export default function Navbar() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(false)
  }
  return (
    <>
      <nav>
        <Container className='flex justify-between items-center py-4'>
          <Link to='/'>
            <img className='w-[130px] md:[160px] lg:w-[200px] h-auto' src={greenLogo} alt='logo' />
          </Link>
          <ul className='hidden lg:flex items-center gap-8 font-medium text-lg '>
            {navData?.map((data) => (
              <li key={data?.id}>
                <NavLink
                  to={data?.link}
                  className={({ isActive }) =>
                    isActive
                      ? 'bg-primary text-white font-bold px-4 py-2 rounded'
                      : 'hover:bg-primary hover:text-white px-4 py-2 rounded'
                  }
                >
                  {data?.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <button className='block lg:hidden' onClick={() => setOpen(true)}>
            <HiOutlineMenu className='h-8 w-8' />
          </button>
        </Container>
      </nav>
      <SideNavbar open={open} handleOpen={handleOpen} />
    </>
  )
}
