import greenLogo from 'assets/images/home/green-logo.webp'
import {
  BsEnvelopeFill,
  BsFacebook,
  BsFillCaretRightFill,
  BsInstagram,
  BsLinkedin,
  BsTelephoneFill,
  BsTwitter,
  BsYoutube,
} from 'react-icons/bs'
import { HiLocationMarker } from 'react-icons/hi'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import Container from './contaienr'

import 'react-lazy-load-image-component/src/effects/blur.css'

export default function Footer() {
  return (
    <footer className='bg-slate-900 text-slate-200'>
      <Container className=''>
        <div className='grid grid-cols-12 gap-4 py-20'>
          <div className='col-span-12 lg:col-span-4 w-full lg:w-11/12'>
            <LazyLoadImage effect='blur' className='w-[200px] h-auto' src={greenLogo} alt='logo' />
            <p className='text-lg mt-6 text-justify'>
              At Segam Group, we are committed to providing top-quality products and services to our
              customers. From Segam Dates to Segam Farms and Segam Trade, we have a wide range of
              offerings to meet your needs.
            </p>
            <ul className='flex mt-6'>
              <li className='bg-primary p-2 rounded-md mr-2 hover:scale-110 ease-in-out duration-500'>
                <a href='https://www.facebook.com/segamgroup' target='_blank' rel='noreferrer'>
                  <BsFacebook />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href='https://twitter.com/segamgroup' target='_blank' rel='noreferrer'>
                  <BsTwitter />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href='https://www.instagram.com/segamgroup' target='_blank' rel='noreferrer'>
                  <BsInstagram />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href='https://www.youtube.com/@segamgroup' target='_blank' rel='noreferrer'>
                  <BsYoutube />
                </a>
              </li>
              <li className='bg-primary p-2 rounded-md ml-2 hover:scale-110 ease-in-out duration-500'>
                <a
                  href='https://www.linkedin.com/in/segamgroup-653b60266'
                  target='_blank'
                  rel='noreferrer'
                >
                  <BsLinkedin />
                </a>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-2'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Helpful Link</h4>
            <ul>
              <li className='mb-1'>
                <Link to='/what-we-do' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    What We Do
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/careers' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Careers
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/customers' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Customers
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/suppliers' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Suppliers
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/sustainability' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Sustainability
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-2'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Segam Group</h4>
            <ul>
              <li className='mb-1'>
                <Link to='/segam-dates' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Segam Dates
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/segam-herbs' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Segam Herbs
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/segam-trade' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Segam Trade
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/segam-farm' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Segam Farm
                  </span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/segam-invest' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:text-primary group-hover:font-medium'>
                    Segam Invest
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-4'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Contact info</h4>
            <div className='flex gap-1'>
              <span>
                <HiLocationMarker className='h-6 w-6' />
              </span>
              <span className='text-lg'>
                (18/1/4) Farrag Mall, Central Axis, 2nd District, 6th Octobar, Giza, Egypt
              </span>
            </div>
            <div className='flex gap-2 items-center my-2'>
              <span>
                <BsTelephoneFill className='h-5 w-5' />
              </span>
              <span className='text-lg'>(+20) 2 38367340/9</span>
            </div>
            <div className='flex gap-2 items-center'>
              <span>
                <BsEnvelopeFill className='h-5 w-5' />
              </span>
              <span className='text-lg'>info@segam-group.com</span>
            </div>
          </div>
        </div>
      </Container>
      <div className='py-3 bg-primary/90'>
        <Container>
          <div className='flex flex-col lg:flex-row justify-between items-center'>
            <p className='text-lg text-center lg:text-left order-2 lg:order-1 mt-2 lg:mt-0'>
              Copyright @{new Date().getFullYear()} Designed by Afro Digital & Developed by
              TechUniq@
            </p>
            <ul className='flex items-center flex-wrap gap-x-3 lg:gap-4 justify-evenly order-1 lg:order-2'>
              <li>
                <Link to='/terms-&-conditions' className='text-lg'>
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link to='/privacy-&-policy' className='text-lg'>
                  Privacy & Policy
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </footer>
  )
}
