import ContactForm from 'components/contact-form'
import Container from 'components/contaienr'
import Header from 'components/header'
import { Heading } from 'components/heading'

export default function Contact() {
  return (
    <div>
      <Header>
        <div className='h-96 md:h-full flex justify-center items-center'>
          <div className='text-center'>
            <h1 className='font-bold text-3xl lg:text-5xl 2xl:text-6xl'>CONTACT US</h1>
          </div>
        </div>
      </Header>
      <Container className='my-28'>
        <Heading>Contact Us</Heading>
        <p className='text-lg text-tertiary pt-3 font-medium pb-12 text-center  max-w-3xl mx-auto'>
          At Segam Group, we are dedicated to connecting producers, manufacturers, and buyers from
          around the world. Our goal is to provide the highest quality products and services to our
          clients, and we would love to hear from you! If you have any questions or comments about
          our services, please don&lsquo;t hesitate to reach out to us.
        </p>

        <div className='p-4 lg:p-10 shadow-md rounded'>
          <ContactForm />
        </div>
      </Container>
    </div>
  )
}
