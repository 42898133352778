import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from './contact'

const Home = React.lazy(() => import('./home'))
const SegamSocial = React.lazy(() => import('./segam-social'))
const Location = React.lazy(() => import('./location'))
const NotFound = React.lazy(() => import('./not-found'))
const Suppliers = React.lazy(() => import('./suppliers'))
const SegamDates = React.lazy(() => import('./segam-dates'))
const WhatWeDo = React.lazy(() => import('./what-we-do'))
const Careers = React.lazy(() => import('./careers'))
const Customers = React.lazy(() => import('./customers'))
const SegamFarm = React.lazy(() => import('./segam-farm'))
const Sustainability = React.lazy(() => import('./sustainability'))
const SegamHerbs = React.lazy(() => import('./segam-herbs'))
const SegamTrades = React.lazy(() => import('./segam-trade'))
const SegamInvest = React.lazy(() => import('./segam-invest'))
const PrivacyAndPolicy = React.lazy(() => import('./privacy-and-policy'))
const TermsAndConditions = React.lazy(() => import('./terms-and-conditions'))

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/suppliers' element={<Suppliers />} />
      <Route path='/what-we-do' element={<WhatWeDo />} />
      <Route path='/sustainability' element={<Sustainability />} />
      <Route path='/careers' element={<Careers />} />
      <Route path='/customers' element={<Customers />} />
      <Route path='/privacy-&-policy' element={<PrivacyAndPolicy />} />
      <Route path='/terms-&-conditions' element={<TermsAndConditions />} />
      <Route path='/segam-farm' element={<SegamFarm />} />
      <Route path='/segam-dates' element={<SegamDates />} />
      <Route path='/segam-herbs' element={<SegamHerbs />} />
      <Route path='/segam-trade' element={<SegamTrades />} />
      <Route path='/segam-invest' element={<SegamInvest />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/location' element={<Location />} />
      <Route path='/segamgroup' element={<SegamSocial />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
